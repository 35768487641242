<template>
    <div class="add-product custom-shadow my-2">
        <itc-product-panel
            v-if="data"
            :product="product"
            ref="controlPanel"
            :data='data'
            type='add'
            @product-update="product = $event"
            @imgs-updated="updateImages">
            <template slot="header">
                <div class="col-3">
                    <button 
                        @click="$emit('click-on-back-button')"
                        class="btn btn-info"> Volver
                    </button>
                </div>
                <div class="text-center col">
                    <h4>Añadir producto</h4>
                </div>
                <div class="col-3">
                    <button 
                    @click="addProduct"
                    :class="{'disabled': disableButton}"
                    class="btn btn-success float-right mx-2" >Añadir</button>
                </div>
            </template>
            <template slot="serial">
                <label for="name">Código de barras</label>
                <input @input="checkProductExistance" type="text" class="form-control" name="name" v-model="product.serial">
                <span class="text-danger">{{errSpan}}</span>
            </template>
            <template slot="providers">
                <label for="providerId">Proveedor</label>
                <select v-model="product.providerId" name="providerId" id="providerId" class="form-control">
                    <option v-for="(provider, i) in data.providers" :key="i" :value="provider._id">{{provider.name}}</option>
                </select>
            </template>
        </itc-product-panel>
    </div>
</template>

<script>
import axios from 'axios'
import ProductControlPanel from '@/components/admin/products/ProductControlPanel'

export default {
    data(){
        return {
            disableButton: false,
            product: {
                monitoring: true
            },
            imgPrimary: '',
            imgSecondary: {
                secondary: null,
                tertiary: null
            },
            data: null,
            errSpan: ''
        }
    },
    methods: {
        async checkProductExistance(){
            if(this.product.serial != ''){
                const resp = await axios.get(`/product/check-existance?serial=${this.product.serial}`)
                this.errSpan = resp.data ? 'Este producto ya existe' : '' 
            }
            else
                this.errSpan = ''
        },
        updateImages(event){
            this.imgPrimary = event.primary;
            this.imgSecondary = event.extras;
            this.disableButton = event.hasErrors
        },
        async addProduct(){
            if(!this.disableButton){
                const fd = new FormData()
                fd.append('images', this.imgPrimary)
                if(this.imgSecondary.secondary)
                    fd.append('images', this.imgSecondary.secondary)
                if(this.imgSecondary.tertiary)
                    fd.append('images', this.imgSecondary.tertiary)
                fd.append('product', JSON.stringify(this.product))
                const resp = await axios.post('/product/add', fd)
                this.$emit('product-added', resp.data)
                this.product = {monitoring: true}
                this.$refs.controlPanel.resetImages()
                this.imgPrimary = ''
                this.imgSecondary = {
                    secondary: null,
                    tertiary: null
                }
            }
        },
    },
    async created(){
        const resp = await axios.get(`/products-control-panel`)
        this.data = resp.data
    },
    components: {
        'itc-product-panel': ProductControlPanel
    },
}
</script>

<style lang="scss" scoped>
.custom-control {
    display: inline;
}
.update-product{
    background:#F5F5F5
}
</style>
