<template>
    <div class="div">
        <itc-cost-calculator 
            :showModal="modals.calculator" 
            :ivas="data.ivas" 
            :dollar="actualDollar"
            @close="modals.calculator = false"
            @submit="updateValues">
        </itc-cost-calculator>
        <itc-card color="#343a40" class="my-2">
            <div slot="header" class="text-white">
                <div class="row">
                    <slot name="header"></slot>
                </div>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <label for="name">Nombre del producto</label>
                        <input ref="productNameInput" type="text" class="form-control" name="name" v-model="product.name">
                    </div>
                    <div class="col-12 col-lg-2 col-sm-6">
                        <slot name="serial"></slot>
                    </div>
                    <div class="col-12 col-lg-2 col-sm-6">
                        <slot name="providers"></slot>
                    </div>
                    <div class="col-12 col-lg-4 custom-margin">
                        <div class="row">
                            <div class="col-6 col-lg-4 form-check">
                                <input name="controlPanelCheckbox" id="monitoring"  v-model="product.monitoring" class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="monitoring">
                                    Monitoreo
                                </label>
                            </div>
                            <div class="col-6 col-lg-4 form-check">
                                <input name="controlPanelCheckbox" id="featured" class="form-check-input" type="checkbox" v-model="product.isFeatured">
                                <label class="form-check-label" for="featured">
                                    Destacado
                                </label>
                            </div>
                            <div class="col-6 col-lg-4 form-check">
                                <input name="controlPanelCheckbox" class="form-check-input" type="checkbox" v-model="product.hide">
                                <label class="form-check-label" for="hidden">
                                    Oculto
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label for="desc">Descripción general</label>
                        <input id="desc" type="text" class="form-control" name="desc" v-model="product.description">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <label for="shortDesc">Descripción corta</label>
                        <input type="text" class="form-control" autocomplete="nope" maxlength="60" id="shortDesc" name="shortDesc" v-model="product.shortDesc">
                    </div>
                    <div class="col-12 col-lg-4">
                        <label for="productCategory">Categoría</label>
                        <select 
                            v-model="product.categoryId"
                            @change="getActualSubCategories($event.target.value)"
                            name="categoryId" id="productCategory" class="form-control">
                            <option v-for="(category, i) in data.categories" :key="i" :value="category._id">{{ category.showName }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-4">
                        <label for="productSubCategory">Sub-categoría</label>
                        <select name="subCategoryId" id="productSubCategory" class="form-control" v-model="product.subCategoryId">
                            <option v-for="(subCategory, i) in actualCategory.subCategories" :key="i" :value="subCategory._id">{{ subCategory.showName }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <label for="cost">Costo</label>
                        <strong class="text-info mx-3 set-pointer" @click="modals.calculator = true">Calcular</strong>
                        <input type="number" id="cost" class="form-control" step="0.0001" name="cost" v-model="product.cost">
                    </div>
                    <div class="col-12 col-lg-2">
                        <label for="profit">Ganancia</label>
                        <input type="number" id="profit" class="form-control" step="0.0001" name="profit" v-model="product.profit">
                    </div>
                    <div class="col-12 col-lg-2">
                        <label for="iva">Iva</label>
                        <select name="iva" id="iva" class="form-control" v-model="product.ivaId">
                            <option v-for="(iva, i) in data.ivas" :key="i" :value="iva._id">{{iva.showName}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-3">
                        <label for="priceInPesos">Costo en pesos<small class="text-muted mx-2">IVA incluido</small></label>
                        <br>
                        <strong>${{costInPesos}}</strong>
                    </div>
                    <div class="col-12 col-lg-3">
                        <label for="price">Precio</label>
                        <br>
                        <strong>${{calculatePrice}}</strong>
                        <br>
                        <span class="text-muted">En pesos: ${{priceInPesos}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <label for="stock">Stock<small class="text-muted sm mx-2">opcional</small></label>
                        <input type="number" id="stock" class="form-control" v-model="product.stock">
                    </div>
                    <div class="col-12 col-lg-3">
                        <label for="minStock">Stock mínimo<small class="text-muted sm mx-2">opcional</small></label>
                        <input type="number" id="minStock" class="form-control" v-model="product.minStock">
                    </div>
                    <div class="col-12 col-md-3">
                        <label for="inputPrimaryImg">Imagen principal</label>
                        <input 
                            @change="onFileChange($event, 'primary')"
                            style="display:none"
                            ref="imgPrimaryFile"
                            type="file" name="primaryImg" id="inputPrimaryImg" class="form-control-file">
                        <br>
                        <button 
                            @click.prevent="$refs.imgPrimaryFile.click()" 
                            :class="{'btn-primary': imgPrimary != 'error', 'btn-danger': imgPrimary == 'error'}"
                            class="btn">
                            Seleccionar
                            
                            <i class="far" 
                                :class="{'fa-check-circle': imgPrimary != 'error', 'fa-times-circle': imgPrimary == 'error'}"
                                v-if="imgPrimary">
                            </i>
                        </button>
                    </div>
                    <div class="col-12 col-md-3">
                        <label for="inputExtraImgs">Imagenes secundarias<small class="text-muted sm mx-2">opcional</small></label>
                        <input 
                            @change="onFileChange($event, 'secondary')"
                            style="display:none"
                            ref="imgSecondaryFile"
                            type="file" name="extraImgs" id="inputExtraImgs" class="form-control-file" multiple>
                        <br>
                        <button 
                            @click.prevent="$refs.imgSecondaryFile.click()" 
                            :class="{'btn-primary': errors.secondary.length == 0, 'btn-danger': errors.secondary.length > 0}"
                            class="btn">
                            Seleccionar
                            <i
                                class="far" 
                                :class="{'fa-check-circle': errors.secondary.length == 0, 'fa-times-circle': errors.secondary.length > 0}" 
                                v-if="imgSecondary && imgSecondary.secondary"></i>
                        </button>
                    </div>
                </div>
                <div v-if="errors.length > 0" class="row">
                    <span v-for="(error, i) in errors" :key="i" class="text-danger">{{error.name}} {{error.data}}</span>
                </div>
                <transition name="fade" mode="out-in">
                    <div v-if="hasErrors" class="errors">
                        <hr>
                        <h4 class="text-center">Errores</h4>
                        <div class="row">
                            <div v-if="errors.primary.length > 0" class="col-6 p-3">
                                <h5>Imagen principal</h5>
                                <hr>
                                <ol>
                                    <li v-for="(error, i) in errors.primary" :key="i" >
                                        <span class="text-danger">{{error.name}}. {{error.data}}</span>
                                    </li>
                                </ol>
                            </div>
                            <div v-if="errors.secondary.length > 0" class="col-6 p-3">
                                <h5>Imagenes secundarias</h5>
                                <hr>
                                <ol>
                                    <li v-for="(error, i) in errors.secondary" :key="i" class="list-item">
                                        <span class="text-danger">{{error.name}}. {{error.data}}</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </itc-card>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

import * as utils from '@/assets/utils/utils.js'

import Card from '@/components/mix/Card'
import Modal from '@/components/mix/Modal'
import CostCalculator from '@/components/admin/products/CostCalculator'

export default {
    data(){
        return {
            actualDollar: 0,
            modals: {
                calculator: false,
                provider: false,
                serial: false
            },
            imgPrimary: '',
            imgSecondary: {
                secondary: null,
                tertiary: null
            },
            actualCategory: {},
            errors: {
                primary: [],
                secondary: []
            }
        }
    },
    props: {
        product: Object,
        data: Object,
        type: String,
    },
    computed: {
        ...mapState(['dollar']),
        hasErrors(){
            return utils.checkErrors(this.errors.primary, this.errors.secondary, null)
        },
        calculatePrice(){
            const ivaValue = utils.getIvaValue(this.data.ivas, this.product.ivaId)
            return utils.getPrice(this.product.profit || 0, this.product.cost || 0, ivaValue || 0)
        },
        priceInPesos(){
            const ivaValue = utils.getIvaValue(this.data.ivas, this.product.ivaId)
            return utils.getPriceInPesos(this.product.profit || 0, this.product.cost || 0, ivaValue || 0, this.actualDollar || 0)
        },
        costInPesos(){
            const ivaValue = utils.getIvaValue(this.data.ivas, this.product.ivaId)
            return utils.getCostInPesos(this.product.cost || 0, ivaValue || 0, this.actualDollar || 0)
        }
    },
    watch: {
        dollar(n, o){
            this.actualDollar = +n
        },
        product: {
            handler(o, n){
                this.product.price = this.calculatePrice
                this.$emit('edit-activated')
            },
            deep: true
        },
    },
    methods: {
        getActualSubCategories(categoryId){
            const findCategory = category => category._id == categoryId
            const index = this.data.categories.findIndex(findCategory)
            this.actualCategory = this.data.categories[index]
        },
        updateValues(values){
            this.product.cost = values.cost
            this.product.ivaId = values.ivaId
        },
        onFileChange(event, type){
            const files = event.target.files
            const errors = []
            const hasErrors = utils.checkFiles(files, errors)
            this.errors[type] = errors
            if(type == 'primary')
                this.imgPrimary = hasErrors ? 'error' : files[0]
            else{
                this.imgSecondary.secondary = hasErrors ? 'error' : files[0]
                if(files[1] && !hasErrors)
                    this.imgSecondary.tertiary = files[1]
                else
                    this.imgSecondary.tertiary = null
            }
            this.$emit('imgs-updated', {primary: this.imgPrimary, extras: this.imgSecondary, hasErrors: utils.checkErrors(this.errors.primary, this.errors.secondary)})
        },
        resetImages(){
            this.$refs.imgPrimaryFile.value = ''
            this.imgPrimary = ''
            this.$refs.imgSecondaryFile.value = ''
            this.imgSecondary = {
                secondary: null,
                tertiary: null
            }
        }
    },
    components: {
        'itc-card': Card,
        'itc-modal': Modal,
        'itc-cost-calculator': CostCalculator
    },
    created(){
        if(this.type == 'update')
            this.getActualSubCategories(this.product.categoryId)
        this.actualDollar = +this.$store.getters.getDollarValue
        this.$nextTick(() => {
            this.$refs.productNameInput.focus()
        })
    },
    activated(){
        if(this.type == 'update')
            this.getActualSubCategories(this.product.categoryId)
        this.$nextTick(() => {
            this.$refs.productNameInput.focus()
        })
    }
}
</script>

<style lang="scss" scoped>
.row{
    margin-top: 10px
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
