<template>
  <itc-modal :showModal="showModal" @close="$emit('close')">
    <div slot="header">
      <h5>{{header}}</h5>
    </div>
    <div slot="body">
      <div class="row">
        <div class="col-12">
          <label for="provider"></label>
          <select 
            v-model="provider"
            class="custom-select" 
            id="provider">
            <option
              v-for="(provider, index) in providers" :key="index" :value="provider">
              {{provider.name}}
            </option>
          </select>
        </div>      
      </div>
    </div>
    <div slot="footer">
      <div class="row">
        <div class="col">
          <button v-if="index != -1" @click="submitProvider('delete')" class="modal-default-button btn btn-danger float-left mx-2">Eliminar</button>
          <button class="modal-default-button btn btn-success float-right mx-2" @click="submitProvider('submit')">OK</button>
          <button class="modal-default-button btn btn-warning float-right mx-2" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </itc-modal>
</template>

<script>
import Modal from '@/components/mix/Modal'
import * as utils from '@/assets/utils/utils.js'

export default {
  data(){
    return {
      provider: {}
    }
  },
  props: {
    showModal: Boolean,
    header: String,
    data: Object,
    index: Number,
    providers: Array
  },
  watch: {
    data(value){
      this.provider = value 
    }
  },
  methods: {
    submitProvider(type){
      this.$emit(`provider-${type}`, {provider: this.provider, index: this.index})
      this.$emit('close')
    }
  },
  components: {
    'itc-modal': Modal
  },
}
</script>
