<template>
  <div class="update-product custom-shadow">
    <itc-provider-modal 
      :showModal="auxModal.showModal.provider"
      :data="provider"
      :header="auxModal.header"
      :index="auxModal.index"
      :providers="unSelectedProviders"
      @close="auxModal.showModal.provider = false"
      @provider-submit="submitProvider"
      @provider-delete="deleteProvider">
    </itc-provider-modal>
    <itc-serial-modal 
      :showModal="auxModal.showModal.serial"
      :data="serial"
      :header="auxModal.header"
      :index="auxModal.index"
      @close="auxModal.showModal.serial = false"
      @serial-submit="submitSerial"
      @serial-delete="deleteSerial">
    </itc-serial-modal>
    <itc-product-panel
      v-if="data"
      :product="product"
      :data="data"
      ref="controlPanel"
      type='update'
      @edit-activated="disableButton.back = true"
      @imgs-updated="updateImages">
      <template slot="header">
        <div class="col-3">
          <button 
            @click="goBack"
            :class="{'disabled': disableButton.back}"
            class="btn btn-info"> Volver
          </button>
        </div>
        <div class="text-center col">
          <h4>Editar producto</h4>
        </div>
        <div class="col-3">
          <button 
            @click="editProduct"
            :class="{'disabled': disableButton.submit}"
            class="btn btn-success float-right mx-2" >Actualizar</button>
          <button 
            @click="deleteProduct"
            class="btn btn-danger float-right mx-2">Eliminar</button>
        </div>
      </template>
      <template slot="serial">
        <div class="custom-margin">
          <div class="dropdown show">
            <a id="serialDropdown" href="#" class="btn btn-secondary dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Códigos de barras
            </a>
            <div class="dropdown-menu" aria-labelledby="serialDropdown">
              <a  
                v-for="(serial, i) in product.serial" :key="i"  
                @click.prevent="openModal('update', 'serial', 'Editar código de barras', i)"
                href="#" class="dropdown-item"> {{serial}}
              </a>
              <div class="dropdown-divider"></div>
              <a  
                @click.prevent="openModal('add', 'serial', 'Añadir código de barras', -1)"
                href="#" class="dropdown-item">
                <div class="text-primary">Añadir</div>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template slot="providers">
        <div class="custom-margin">
          <div class="dropdown show">
            <a id="serialDropdown" href="#" class="btn btn-secondary dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Proveedores
            </a>
            <div class="dropdown-menu" aria-labelledby="serialDropdown">
              <a 
                v-for="(provider, i) in product.providerId" :key="i"
                @click.prevent="openModal('update', 'provider', 'Editar proveedor', i)"
                href="#" class="dropdown-item">{{provider.name}}</a>
              <div class="dropdown-divider"></div>
              <a 
                @click.prevent="openModal('add', 'provider', 'Añadir proveedor', -1)"
                href="#" class="dropdown-item"><div class="text-primary">Añadir</div></a>
            </div>
          </div>
        </div>
      </template>
    </itc-product-panel>
  </div>
</template>

<script>
import axios from 'axios'

import ProductControlPanel from '@/components/admin/products/ProductControlPanel'
import SerialModal from '@/components/admin/products/SerialModal'
import ProviderModal from '@/components/admin/products/ProviderModal'

export default {
  data(){
    return {
      auxModal: {
        header: '',
        showModal: {serial: false, provider: false},
        index: -1
      },
      serial: '',
      provider: {},
      disableButton: {
        submit: false,
        back: false
      },
      imgPrimary: '',
      imgSecondary: {
        secondary: null,
        tertiary: null
      },
      data: null,
      isEdited: false
    }
  },
  props: {
    product: Object
  },
  computed: {
    unSelectedProviders() {
      return this.data?.providers.filter(p => this.product.providerId.findIndex(el => el._id == p._id) == -1 || p._id == this.provider?._id)
    }
  },
  methods: {
    goBack(){
      if(!this.disableButton.back)
        this.$emit('click-on-back-button')
    },
    deleteProvider(event){
      if(this.product.providerId.length == 1) return this.$toasted.error('No puedes eliminar todos los proveedores')
      this.product.providerId.splice(event.index, 1)
    },
    submitProvider(event){
      if(event.index != -1)
        this.product.providerId.splice(event.index, 1, event.provider)
      else
        this.product.providerId.push(event.provider)
    },
    deleteSerial(event){
      this.product.serial.splice(event.index, 1)
    },
    submitSerial(event){
      if(event.index != -1)
        this.product.serial.splice(event.index, 1, event.serial)
      else
        this.product.serial.push(event.serial)
    },
    openModal(action, type, header, index){
      this.auxModal.header = header
      if(action == 'add')
        this[type] = null
      else
        this[type] = type == 'serial' ? this.product.serial[index] : this.product.providerId[index]
      this.auxModal.index = index
      this.auxModal.showModal[type] = true
    },
    updateImages(event){
      this.imgPrimary = event.primary,
        this.imgSecondary = event.extras
      this.disableButton.back = event.hasErrors
      this.disableButton.submit = event.hasErrors
    },
    async editProduct(){
      if(!this.disableButton.submit){
        const fd = new FormData()
        fd.append('images', this.imgPrimary)
        if(this.imgSecondary.secondary)
          fd.append('images', this.imgSecondary.secondary)
        if(this.imgSecondary.tertiary)
          fd.append('images', this.imgSecondary.tertiary)
        fd.append('product', JSON.stringify(this.product))
        const resp = await axios.post('/product/update', fd)
        this.disableButton.back = false
        this.$refs.controlPanel.resetImages()
        this.imgPrimary = ""
        this.imgSecondary = {
          secondary: null,
          tertiary: null
        }
        this.$emit('product-updated', resp.data)
      }
    },
    async deleteProduct(){
      const bool = confirm('Seguro que quieres eliminar este producto?')
      if(bool){
        axios.post('/product/delete', {id: this.product._id})
        this.$emit('product-deleted')
      }
    }
  },
  activated(){
    this.disableButton.back = false
  },
  async created(){
    const resp = await axios.get(`/products-control-panel`)
    this.data = resp.data
  },
  components: {
    'itc-product-panel': ProductControlPanel,
    'itc-serial-modal': SerialModal,
    'itc-provider-modal': ProviderModal,
  },
}
</script>

<style lang="scss" scoped>
.custom-control {
  display: inline;
}
.update-product{
  background:#F5F5F5
}
</style>
