<template>
    <itc-modal :showModal="showModal" @close="showModal = false">
        <div slot="header">
            <h5>Calcular costo</h5>
        </div>
        <div slot="body">
            <h6 class="text-warning">Conversor</h6>
            <div class="row">
                <div class="col-6">
                    <label for="costCalculatorConversor">Costo en pesos</label>
                    <input ref="costConversor" @input="updateCost" type="number" step="0.0001" id="costCalculatorConversor" class="form-control">
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <label for="costCalculatorCost">Costo (US$)</label>
                    <input v-model="cost" type="number" step="0.0001" id="costCalculatorCost" class="form-control">
                </div>
                <div class="col-12 col-lg-6">
                    <label for="costCalculatorIva">Iva</label>
                    <select v-model="iva" id="costCalculatorIva" class="form-control">
                        <option v-for="(iva, i) in ivas" :key="i" :value="{id: iva._id, value: iva.value}">{{iva.showName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col">
                  <button class="modal-default-button btn btn-success float-right mx-2" @click="submitCostCalculator">OK</button>
                  <button class="modal-default-button btn btn-warning float-right mx-2" @click="$emit('close')">Cerrar</button>
                </div>
            </div>
        </div>
    </itc-modal>
</template>

<script>
import Modal from '@/components/mix/Modal'
import * as utils from '@/assets/utils/utils.js'

export default {
    data(){
        return {
            cost: 0,
            iva: {
                id: '',
                value: 0
            }
        }
    },
    watch: {
        showModal: function (val){
            if(val){
                this.$nextTick(() => {
                   this.$refs.costConversor.focus()
                })
            }
        }
    },
    methods: {
        submitCostCalculator(){
            const wIvaCost = (this.cost / this.iva.value).toFixed(3)
            this.$emit('submit', {cost: wIvaCost, ivaId: this.iva.id})
            this.$emit("close")
        },
        updateCost(event){
            this.cost = utils.costInDollars(event.target.value, this.dollar)
        },
    },
    props: {
        showModal: Boolean,
        ivas: Array,
        dollar: Number
    },
    components: {
        'itc-modal': Modal
    }
}
</script>