<template>
    <itc-modal :showModal="showModal" @close="$emit('close')">
        <div slot="header">
            <h5>{{header}}</h5>
        </div>
        <div slot="body">
            <div class="row">
                <div class="col-12">
                    <label for="costCalculatorConversor"></label>
                    <input v-model="serial" type="text" id="costCalculatorConversor" class="form-control">
                </div>      
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col">
                  <button v-if="index != -1" @click="submitSerial('delete')" class="modal-default-button btn btn-danger float-left mx-2">Eliminar</button>
                  <button class="modal-default-button btn btn-success float-right mx-2" @click="submitSerial('submit')">OK</button>
                  <button class="modal-default-button btn btn-warning float-right mx-2" @click="$emit('close')">Cerrar</button>
                </div>
            </div>
        </div>
    </itc-modal>
</template>

<script>
import Modal from '@/components/mix/Modal'
import * as utils from '@/assets/utils/utils.js'

export default {
    data(){
        return {
            serial: ''
        }
    },
    props: {
        showModal: Boolean,
        header: String,
        data: String,
        index: Number
    },
    watch: {
        data(value){
            this.serial = value 
        }
    },
    methods: {
        submitSerial(type){
            this.$emit(`serial-${type}`, {serial: this.serial, index: this.index})
            this.$emit('close')
        }
    },
    components: {
        'itc-modal': Modal
    },
}
</script>
