<template>
    <div class="products">
        <h4 class="pt-3">
            Productos
            <button @click="actualComponent = 'itc-product-add'" class="mx-2 btn btn-success btn-sm">
                <i class="fas fa-plus"></i>
            </button>
            <button @click="sendStockControl" class="float-right btn btn-primary send-stock">
                Enviar control de stock
                <img class="loading-gif" v-show="loading" src="@/assets/loading.svg" alt="">
            </button>   
        </h4>
        <hr>
        <itc-products-searcher :searchFunction="searchProducts" class="my-4"></itc-products-searcher>
        <transition name="fade" mode="out-in">
            <keep-alive>
                <component
                    :is="actualComponent"
                    :products="products"
                    :product="product"
                    :selectProductToUpdate="selectProductToUpdate"
                    @click-on-back-button="actualComponent = 'itc-products-table'"
                    @product-added="actualComponent = 'itc-products-table';products.splice(0, 0, $event)"
                    @product-updated="actualComponent = 'itc-products-table';products.splice(updateIndex, 1);products.splice(0, 0, $event)"
                    @product-deleted="actualComponent = 'itc-products-table';products.splice(updateIndex, 1)"
                    @submit="actualComponent = 'itc-products-table'">
                </component>
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import axios from 'axios'

import ProductsTable from '@/components/admin/products/ProductsTable'
import ProductsSearcher from '@/components/admin/products/ProductsSearcher'
import ProductUpdate from '@/components/admin/products/ProductUpdate'
import ProductAdd from '@/components/admin/products/ProductAdd'

export default {
 data(){
     return {
         loading: false,
         products: [],
         product: {},
         actualComponent: 'itc-products-table',
         updateIndex: null
     }
 },
 methods: {
    async sendStockControl(){
        this.loading = true
        await axios.get('/stock-control')
        this.loading = false
        this.$toasted.success('Control de stock enviado')
     },
     async searchProducts(searcher, type){
        const resp = type=='category'   ? await axios.get(`/product/category?categoryUrlName=${searcher.categoryUrlName}&subCategoryUrlName=${searcher.subCategoryUrlName}&mode=complete`) 
                                        : await axios.get(`/product/${type}?searcher=${searcher}${type == 'featured' ? '&searchInStock=false' : ''}`)
        this.actualComponent = 'itc-products-table'
        this.products = resp.data
     },
     selectProductToUpdate(index){
        this.actualComponent = 'itc-product-update'
        this.product = this.products[index]
        this.updateIndex = index
    }
 },
 components: {
     'itc-products-table': ProductsTable,
     'itc-products-searcher': ProductsSearcher,
     'itc-product-update': ProductUpdate,
     'itc-product-add': ProductAdd
 },
 async created(){
     const resp = await axios.get('/product/lastUpdate');
     this.products = resp.data
 }   
}
</script>

<style lang="scss" scoped>
.loading-gif{
    width: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>