<template>
    <div class="products-list custom-shadow">
        <itc-modal :showModal="showImg" @close="showImg = false">
            <div slot="header">
                {{header}}
            </div>
            <div slot="body" class="w-100 text-center">
                <img :src="imgPath" id="modalImg" class="showModal-content"> </div>
        </itc-modal>
        <table id="tablePreview" class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>Nombre</th>
                    <th>Precio (US$)</th>
                    <th>Precio (AR$)</th>
                    <th>Stock</th>
                    <th>Fecha actualizacion</th>
                    <th>Código</th>
                    <th>Foto</th>
                </tr>
            </thead>
            <tbody>
                <tr  v-if="products.length == 0">
                    <td colspan="7" class="text-center">
                        <h1>Sin productos</h1>
                    </td>
                </tr>
                <tr
                    v-for="(product, i) in products" 
                    :key="i" 
                    class="product-item set-pointer"
                    @click.exact="selectProductToUpdate(i)"
                    @click.ctrl="copyToClipboard(product._id)">
                    <th scope="row">{{product.name}}</th>
                    <td>${{product.price}}</td>
                    <td>${{product.price | convertToPesos(dollar)}}</td>
                    <td>{{product.stock}}</td>
                    <td>{{product.lastUpdate}}</td>
                    <td>{{product.serial[0]}}</td>
                    <td class="set-pointer" @click.stop="showImgModal(product.imgPath.primary, product.name)"><i class="far fa-image text-success"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Modal from '@/components/mix/Modal'

import * as utils from '@/assets/utils/utils.js'

export default {
    data(){
        return {
            showImg: false,
            header: '',
            imgPath: '',
            dollar: null
        }
    },
    methods: {
        copyToClipboard(productId) {
            const text = `https://itcentroweb.com.ar/shop/product/${productId}`
            var input = document.createElement('textarea');
            input.innerHTML = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            this.$toasted.success('Copiado al portapapeles')
        },
        showImgModal(path, name){
            this.imgPath = `${process.env.VUE_APP_PUBLIC_PATH}${path}`;
            this.showImg = true;
            this.header = name
        }
    },
    components: {
        'itc-modal': Modal
    },
    props: {
        products: Array,
        selectProductToUpdate: Function
    },
    filters: {
        convertToPesos(price, dollar){
            return utils.roundPrice(price, dollar)
        }
    },
    created(){
        this.dollar = this.$store.getters.getDollarValue
    }
}
</script>

<style lang="scss" scoped>
.product-item:hover{
    background-color: lightgreen;
}
</style>
