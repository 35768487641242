<template>
    <div class="searcher custom-shadow">
        <itc-modal :showModal="showCategoryModal">
            <div slot="header">
                Categoría
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-2">
                        <label for="productCategory">Categoría</label>
                        <select 
                            @change="getActualSubCategories($event.target.value)"
                            name="categoryId" id="productCategory" class="form-control">
                            <option v-for="(category, i) in categories" :key="i" :value="category._id">{{ category.showName }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-6">
                        <label for="productSubCategory">Sub-categoría</label>
                        <select name="subCategoryId" id="productSubCategory" class="form-control" v-model="actualSubCategory">
                            <option v-for="(subCategory, i) in actualCategory.subCategories" :key="i" :value="subCategory">{{ subCategory.showName }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button 
                            class="modal-default-button btn btn-success float-right mx-2" 
                            @click="searchFunction({categoryUrlName: actualCategory.urlName, subCategoryUrlName: actualSubCategory.urlName}, 'category');showCategoryModal = false">OK
                        </button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showCategoryModal = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </itc-modal>
        <itc-card color="#343a40">
            <div slot="header" class="text-center text-white">
                <h4>Buscador</h4>
            </div>
            <div slot="body" class="row">
                <div class="col-2">
                    <div class="searchByName">
                        <label for="nameSearcher">Por nombre</label>
                        <input
                            ref="nameInput"
                            v-model="nameSearcher" 
                            @keyup.enter="searchFunction(nameSearcher, 'name');nameSearcher = ''"
                            class="form-control" type="search" id="nameSearcher" placeholder="Search" aria-label="Search">
                    </div>
                </div>
                <div class="col-2">
                    <div class="searchBySerial">
                        <label for="serualSearcher">Por código de barras</label>
                        <input
                            v-model="serialSearcher" 
                            @keyup.enter="searchFunction(serialSearcher, 'serial');serialSearcher = ''"
                            class="form-control" type="search" id="serualSearcher" placeholder="Search" aria-label="Search">
                    </div>
                </div>
                <div class="col-12 col-lg-6 custom-margin">
                    <div class="row">
                        <div class="col-3 form-check">
                            <input @input="advancedSearch($event, 'noMonitoring')" name="advancedSearch" id="noMonitoring" class="form-check-input" type="radio">
                            <label class="form-check-label" for="noMonitoring">
                                Sin monitorear
                            </label>
                        </div>
                        <div class="col-3 form-check">
                            <input @input="advancedSearch($event, 'featured')" name="advancedSearch" class="form-check-input" type="radio">
                            <label class="form-check-label" for="featured">
                                Destacado
                            </label>
                        </div>
                        <div class="col-3 form-check">
                            <input @input="advancedSearch($event, 'noImg')" name="advancedSearch" class="form-check-input" type="radio">
                            <label class="form-check-label" for="noImg">
                                Sin imagen
                            </label>
                        </div>
                        <div class="col-3 form-check">
                            <input @input="advancedSearch($event, 'hide')" name="advancedSearch" id="hide" class="form-check-input" type="radio">
                            <label class="form-check-label" for="hide">
                                Oculto
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <button @click="showCategoryModal = true" class="mt-4 btn btn-primary">
                        Categoría
                    </button>
                </div>
            </div>
        </itc-card>
    </div>
</template>

<script>
import Card from '@/components/mix/Card'
import Modal from '@/components/mix/Modal'

import axios from 'axios'

export default {
    data(){
        return {
            nameSearcher: '',
            serialSearcher: '',
            showCategoryModal: false,
            categorySeacher: {
                category: '',
                subCategory: ''
            },
            categories: {},
            actualCategory: {},
            actualSubCategory: {}
        }
    },
    components: {
        'itc-card': Card,
        'itc-modal': Modal
    },
    methods: {
        getActualSubCategories(categoryId){
            const findCategory = category => category._id == categoryId
            const index = this.categories.findIndex(findCategory)
            this.actualCategory = this.categories[index]
        },
        advancedSearch(event, type){
            if(event.target.checked) this.searchFunction(null, type)
        }
    },
    async created(){
        const resp = await axios.get('/category/complete')
        this.categories = resp.data
        this.actualCategory = this.categories[0]
        this.$nextTick(() => {
            this.$refs.nameInput.focus()
        })
    },
    props: {
        searchFunction: Function
    }
}
</script>

<style lang="scss" scoped>
.custom-border{
    border-bottom: 1px solid lightgray;
}
</style>